<template>
  <div class="container-fluid">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>
          Relatório de Credenciais por QR Code
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="nome">Empresa</label>
          <select2 
            :ItemsProp="empresas"
            :FieldProp="'nome_fantasia'"
            :PlaceholderTextProp="'Pesquisar empresa'"
            :ClearAfterSelectedProp="false"
            :SelectedItemProp="empresaSelecionada"
            @Selected="EmpresaSelecionada($event)">  
          </select2>
        </div>
      </div>
      <div class="mr-5 align-self-end">        
        <div class="form-group">
          <button type="button" class="btn btn-primary" @click="gerarRelatorio">GERAR</button>
        </div>
      </div>
    </div>
    <div  class="row mt-5"  v-if="dataItems.length > 0">
      <div class="col-12 mb-3 text-right">
        <button type="button" class="btn btn-primary" @click="PrintElem">Salvar Lista</button>
      </div>
      <div id="printtable" class="col-12">
        <table class=" mt-5 table table-hover border table-striped table-bordered">
          <thead>
            <td>Nome</td>
            <td>QR Code</td>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataItems" :key="index">
              <td>{{item.nome_funcionario}}</td>
              <td>
                <qrcode-vue :value="geraUrl(item.id)" level="H" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import funcionariosRepository from '../../services/api/funcionariosRepository'
import empresaRepository from '../../services/api/empresaRepository'
import select2 from './../../uicomponents/select2'
import QrcodeVue from 'qrcode.vue'
import html2pdf from 'html2pdf.js'
export default {
  components: {
    select2,
    QrcodeVue
  },
  data () {
    return {
      filtro: {
        empresa_id: null,
        treinamento_id: null,
        data_inicio: '',
        data_fim: ''
      },
      isLoading: false,
      empresaSelecionada: null,
      treinamentoSelecionado: null,
      treinamentos: [],
      treinamentosFiltrado: [],
      empresas: [],
      dataItems: [],
      funcionariosEmpresa: []
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    geraUrl(id) {
      let code = btoa(id)
      return `https://api.vidamg.com.br/credencial/validar/${code}/${this.empresaSelecionada.id}`
    },
    async fetch() {
      try {
        this.isLoading = true
        let responseE = await empresaRepository.listarUnidades()
        if(responseE.data['success']) {
          this.empresas = responseE.data['data']
        }
      }catch (e) {
        console.log(e)
        this.isLoading = false
        this.$swal({
          icon: 'error',
          text: 'Não foi possível buscar os dados básicos de filtro!'
        })
      }finally {
        this.isLoading = false
      }
    },
    EmpresaSelecionada(empresa) {
      console.log(empresa)
      this.empresaSelecionada = empresa
    },
    gerarRelatorio() {
      if(!this.empresaSelecionada) {
        this.$swal({
          icon: 'warning',
          text: 'Selecione a empresa!'
        })
        return
      }
      this.isLoading = true
      funcionariosRepository.buscaFuncionariosPorEmpresa(this.empresaSelecionada.id).then(response => {
        this.isLoading = false
        console.log(response.data)
        this.dataItems = response.data.data
      }).catch(() => {
        this.isLoading = false
        this.$swal({
          icon: 'error',
          text: 'Ocorreu um erro ao gerar o relatório!'
        })
      })
    },
    PrintElem() {
      var element = document.getElementById('printtable')
      html2pdf().from(element).outputImg().save('credenciais')
    }
  }
}
</script>

<style>

</style>